import { format } from 'date-fns'
import enUS from 'date-fns/locale/en-US'
import ja from 'date-fns/locale/ja'
import i18next from 'i18next'
import { initReactI18next, useTranslation } from 'react-i18next'
import { proxy, useSnapshot } from 'valtio'
import { devtools, subscribeKey } from 'valtio/utils'
import messages from './en.json'

const LANG = 'SELECTED_LANG'

export type Lang = 'en' | 'ja'

export const languageStore = proxy({
  lang: initialLang(),
})
if (import.meta.env.DEV) {
  devtools(languageStore, 'language')
}

i18next.use(initReactI18next).init({
  // console message が多すぎるので表示しない
  // debug: import.meta.env.DEV,
  resources: {
    en: { translation: messages },
  },
  lng: languageStore.lang,
  fallbackLng: false, // フォールバックしない＝keyをそのまま表示
  returnEmptyString: false, // 空文字での定義を許可
})

function isLang(value: string | null): value is Lang {
  return !!value && /^(en|ja)$/.test(value)
}

function initialLang(): Lang {
  const lang = localStorage.getItem(LANG)
  if (isLang(lang)) return lang
  return navigator.languages[0].includes('ja') ? 'ja' : 'en'
}

subscribeKey(languageStore, 'lang', (value) => {
  localStorage.setItem(LANG, value)
  i18next.changeLanguage(value)
})

export function useJa() {
  languageStore.lang = 'ja'
}

export function useEn() {
  languageStore.lang = 'en'
}

export function isJa(): boolean {
  return languageStore.lang === 'ja'
}

interface FormatOption {
  formatString?: string
  asUtc?: boolean // 1993-10-27 のように日付までなので時刻変換しない
}

export function formatDate(date?: Date | number | null, opt?: string | FormatOption): string {
  if (date === undefined || date === null) return ''
  const formatString = typeof opt === 'string' ? opt : opt?.formatString
  const asUtc = typeof opt === 'object' && opt.asUtc === true
  const locale = isJa() ? ja : enUS
  const src = typeof date === 'number' ? new Date(date) : date
  const normalized = asUtc
    ? new Date(src.getUTCFullYear(), src.getUTCMonth(), src.getUTCDate())
    : src
  return format(normalized, formatString || 'PP', { locale })
}

export function formatYearMonth(date?: Date | number | null): string {
  return formatDate(date, { formatString: 'y/MM', asUtc: true })
}

export function useLang() {
  const [t] = useTranslation()
  const language = useSnapshot(languageStore)
  return { t, language: language.lang }
}

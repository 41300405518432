import { extendTheme } from '@chakra-ui/react'
const theme = extendTheme({
  styles: {
    global: {
      body: {
        fontSize: '14px',
      },
      '@media print': {
        body: {
          fontSize: '9pt',
        },
        ':root': {
          '--chakra-fontSizes-sm': '9pt',
        },
      },
    },
  },
  fonts: {
    // 印刷用に Noto Sans CJK JP 使う
    body: '"Noto Sans", "Helvetica Neue", Arial, "Noto Sans CJK JP Regular", "Hiragino Kaku Gothic ProN", "Hiragino Sans", "BIZ UDPGothic", Meiryo, sans-serif;',
  },
})
export default theme
